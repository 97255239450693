<template>
  <footer>
    <div class="form-field">
      <div class="form">
        <label for="comment">
          <input
            id="comment"
            name="comment"
            placeholder="我也来说两句..."
            type="text"
            @input="handleInput"
            @focus="onFocus"
            ref="input"
          />
        </label>
      </div>
      <img
        class="submit submit-news"
        src="@/assets/img/chat/icon90.png"
        alt=""
        @click="submit"
      />
      <!-- src="https://www.bzwz.com/static/m/images/icon90.png" -->
    </div>
  </footer>
</template>

<script lang="ts">
import { computed, defineComponent,onMounted,ref, watch } from "vue";
import { useRouter } from "vue-router";
export default defineComponent({
  name: "PublishComment",
  props: {
    modelValue: {
      type: String,
      default: "",
    },
  },
  setup(props, ctx) {
    function submit() {
      ctx.emit("submit");
    }
    const router = useRouter();
    function onFocus() {
      if (!localStorage.getItem("token")) {
        router.push({
          path: "/login",
          query: {
            redirect: router.currentRoute.value.fullPath,
          },
        });
      }
    }
    const input = ref<HTMLInputElement | null>(null)
    const nativeValue = computed(()=>{
       return props.modelValue === undefined ? '' : props.modelValue
    })
    function setNativeValue() {
      console.log(nativeValue.value); 
      
      (input.value as HTMLInputElement).value = nativeValue.value
    }
    function handleInput(e: Event) {
      ctx.emit("update:modelValue", (e.target as HTMLInputElement).value);
    }

    watch(()=>{
      return props.modelValue
    },()=>{
      setNativeValue()
    })
    onMounted(()=>{
      setNativeValue()
    })


    return {
      handleInput,
      submit,
      onFocus,
      input
    };
  },
});
</script>

<style lang="scss" scoped>
footer {
  position: fixed;
  height: 1.493rem;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  border-top: 1px solid #eee;
  z-index: 100;
}

.form-field {
  width: 9.733rem;
  height: 1.067rem;
  margin: 0.213rem auto;
  position: relative;
  padding-right: 1.093rem;
  box-sizing: border-box;
  background-color: #f4f4f4;
  line-height: 1.067rem;
}
.form-field input {
  width: 100%;
  height: 1.067rem;
  line-height: 1.067rem;
  outline: none;
  border: none;
  background-color: transparent;
  vertical-align: middle;
  padding-left: 0.4rem;
  box-sizing: border-box;
  font-size: 0.427rem;
}

.form-field .submit {
  width: 0.48rem;
  height: 0.467rem;
  position: absolute;
  right: 0.267rem;
  top: 0.293rem;
}

.articleVideo {
  width: 8.933rem;
  height: 5.013rem;
  margin: 0 auto;
}
</style>